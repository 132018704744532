import React, { useEffect } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import { ReactComponent as ArticleReview } from "../../../assets/images/ArticleReview.svg";
import { repoPolicyPoints } from "../../../helpers/constant";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchJournalById } from "../../../redux/slices/webJournal";
import * as DOMPurify from "dompurify";
import htmlParser from "html-react-parser";

const StepsOl = styled("ol")(({ theme }) => ({
  counterReset: "peerSteps",
  listStyle: "none",
  margin: "0",
  paddingLeft: "23px",

  "& > li": {
    marginBottom: "20px",
    display: "table",
    counterIncrement: "peerSteps",
    listStyleType: "none",

    "&:before": {
      display: "table-cell",
      content: '"Step " counters(peerSteps, ".") ": "',
      paddingRight: "0.6em",
      width: "65px",
    },
  },
}));

const RepoPolicy = () => {
  const dispatch = useDispatch();
  const { journalId } = useParams();

  const { journalDetails, journalLoading } = useSelector(
    (state) => state.webJournalReducer
  );

  useEffect(() => {
    if (journalId) {
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        wordBreak: "break-word",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Box sx={{ marginBottom: "30px" }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
            marginBottom: "20px",
          }}
        >
          REPOSITORY POLICY
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          At <strong>{journalDetails?.name}</strong> Journal, we encourage
          authors to deposit their work in institutional or other repositories
          to ensure broader access and visibility. Below are our policies
          regarding the different versions of a paper:
        </Typography>
      </Box>

      <ol>
        {repoPolicyPoints?.points?.map((point) => (
          <li>
            {" "}
            <Typography
              //   variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              {htmlParser(DOMPurify.sanitize(point.title))}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              {point.description}
            </Typography>
          </li>
        ))}
      </ol>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          marginBottom: "20px",
        }}
      >
        {repoPolicyPoints.footerDescription}
      </Typography>
    </Box>
  );
};

export default RepoPolicy;
