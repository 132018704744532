import {
  Box,
  CircularProgress,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { CustomTextField } from "../../../components";
import journalWebsiteServices from "../../../services/journal/website";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";

const SearchResult = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const [search, setSearch] = useState({
    text: searchParams.get("text") || "",
    data: [],
    loading: false,
  });

  useEffect(() => {
    const fetchSearch = async () => {
      setSearch((prev) => ({
        ...prev,
        loading: true,
      }));
      const resSearch =
        await journalWebsiteServices.getSearchJournalArticleList({
          searchKey: search.text,
        });
      if (resSearch?.data?.length > 0) {
        setSearch((prev) => ({
          ...prev,
          data: resSearch.data,
          loading: false,
        }));
      } else {
        setSearch((prev) => ({
          ...prev,
          data: [],
          loading: false,
        }));
      }
    };
    fetchSearch();
  }, [search.text]);

  const handleSearch = useMemo(
    () =>
      debounce((e) => {
        setSearch((prev) => ({
          ...prev,
          text: e.target.value,
        }));
      }, 300),
    []
  );

  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        textAlign: "justify",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Box>
        <CustomTextField
          placeholder="Search for Department, Journals or any article"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="center">
                <i
                  className="ri-search-2-line ri-lg"
                  style={{ marginLeft: "5px" }}
                ></i>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "30px",
            },
          }}
          onChange={(e) => handleSearch(e)}
        />
      </Box>
      {search?.loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <Typography
            variant="body"
            gutterBottom
            sx={{
              color: theme.palette.neutral[80],
              fontWeight: 600,
              "& span": {
                color: theme.palette.secondary.main,
                //   fontSize: "18px",
              },
            }}
          >
            {" "}
            <CircularProgress size={20} /> Loading
          </Typography>
        </Box>
      ) : search.data?.length > 0 ? (
        <Box
          sx={{
            padding: "30px 10px",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              color: theme.palette.neutral[80],
              "& span": {
                color: theme.palette.secondary.main,
                fontSize: "18px",
                fontWeight: 600,
              },
            }}
          >
            Search results for "<span>{search.text}</span>"
          </Typography>
          <Box>
            <ul
              style={{
                listStyle: "none",
                padding: 0,
              }}
            >
              {search.data.map((searchData) => (
                <li
                  style={{
                    borderBottom: `1px solid ${theme.palette.neutral[20]}`,
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    variant="caption"
                    gutterBottom
                    sx={{
                      color: theme.palette.neutral[50],
                    }}
                  >
                    {searchData.documentType}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      color: theme.palette.info.main,
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      searchData.documentType === "Article"
                        ? window.open(`/article/${searchData?._id}`)
                        : window.open(
                            `/journal/${searchData?.journalCustomId}`
                          );
                    }}
                  >
                    {searchData.name}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      ) : (
        search.text &&
        search.data.length <= 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                color: theme.palette.neutral[80],
                fontWeight: 600,
                "& span": {
                  color: theme.palette.secondary.main,
                  //   fontSize: "18px",
                },
              }}
            >
              No search Results found for "<span>{search.text}</span>"
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
};

export default SearchResult;
