import getJournalListWebsite from "./getJournalListWebsite";
import getJournalViewWebsite from "./getJournalViewWebsite";
import getSearchJournalArticleList from "./getSearchJournalArticleList";

const journalWebsiteServices = {
  getJournalListWebsite,
  getJournalViewWebsite,
  getSearchJournalArticleList,
};

export default journalWebsiteServices;
