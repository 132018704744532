import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  styled,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DocumentImage from "../../assets/images/Document.jpg";
import { CustomTextField } from "../../components";
import {
  PublishWithCardDisplay,
  PublishWithCardSection,
  PublishWithCardWithInput,
  SupportHubCard,
} from "../../components/website/landingPage";
import {
  publishWithIFRDiscoveryCards,
  publishWithIFRDiscoveryDisplayCards,
  supportHubCards,
} from "../../helpers/constant";
import { ReactComponent as TeamWork1 } from "../../assets/images/TeamWork1.svg";
import { ReactComponent as TeamWork2 } from "../../assets/images/TeamWork2.svg";
import { ReactComponent as ArticleWriting } from "../../assets/images/ArticleWriting.svg";
import { ReactComponent as ArticleEditor } from "../../assets/images/ArticleEditor.svg";
import { ReactComponent as ArticleReview } from "../../assets/images/ArticleReview.svg";
import { ReactComponent as Assistance } from "../../assets/images/Assistance.svg";
import { ReactComponent as CreativeTeam } from "../../assets/images/CreativeTeam.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setReduxJournalDetails } from "../../redux/slices/webJournal";

const SearchJournalArticleField = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");

  return (
    <CustomTextField
      placeholder="Search for Department, Journals or any article"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="center"
            // sx={{

            // }}
          >
            <i
              className="ri-search-2-line ri-lg"
              style={{
                marginLeft: "5px",
                background: theme.palette.primary.main,
                padding: "12px 10px",
                borderRadius: "50%",
                color: theme.palette.neutral[0],
                cursor: "pointer",
              }}
              onClick={() => navigate(`/search?text=${searchValue}`)}
            ></i>
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiInputBase-root": {
          borderRadius: "30px",
        },
      }}
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  );
};

function Home() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const mediaQuery1020 = useMediaQuery("(min-width:1020px)");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setReduxJournalDetails(null));
  }, [dispatch]);

  return (
    <Box>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ p: 3 }}
      >
        <Grid item xs={mediaQuery1020 ? 6 : 12}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: 600,
            }}
          >
            International Federation of Research And Discovery (IFR Discovery)
          </Typography>
          <Typography variant="p" gutterBottom>
            Welcome to our International scientific publishing platform,
            offering comprehensive scientific publication services within our
            proprietary platform, featuring a global network from Jordan. We
            also provide expert consulting services to further enhance the
            publishing experience
          </Typography>
          <Box sx={{ marginTop: "50px" }}>
            <SearchJournalArticleField />
          </Box>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Button
                sx={{
                  "&.MuiButton-root": {
                    color: theme.palette.neutral[90],
                    backgroundColor: theme.palette.primary.main,
                    textTransform: "none",
                    borderRadius: "30px",
                    padding: "10px 20px",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.hover,
                    },
                  },
                }}
                // size="small"
                onClick={() => navigate("/journal")}
              >
                Browse Journals
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Button
                sx={{
                  "&.MuiButton-root": {
                    color: theme.palette.neutral[0],
                    backgroundColor: theme.palette.secondary.main,
                    textTransform: "none",
                    borderRadius: "30px",
                    padding: "10px 20px",
                    // marginLeft: "10px",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: theme.palette.secondary.hover,
                    },
                  },
                }}
                // size="small"
                onClick={() => navigate("/article/submit")}
              >
                Submit Article
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {mediaQuery1020 && (
          <Grid item xs={6}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                alt="landing-page-document"
                src={DocumentImage}
                style={{ maxWidth: "75%" }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ marginTop: "20px", p: 3 }}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: 600,
              textAlign: "center",
              // width: "100%",
              padding: "10px",
              marginBottom: "30px",
            }}
          >
            Publish With IFR Discovery
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <PublishWithCardSection />
          {/* {publishWithIFRDiscoveryCards.map(
            (publishWithCard, publishWithCardIndex) => (
              <Grid
                item
                xs={12}
                sm={publishWithCard?.tag === "browse" ? 12 : 6}
                md={4}
                key={`publishWithCard_${publishWithCardIndex}`}
              >
                <PublishWithCardWithInput
                  title={publishWithCard?.title}
                  buttonText={publishWithCard?.buttonText}
                  inputProps={{
                    placeholder: publishWithCard?.inputPlaceholder,
                  }}
                />
              </Grid>
            )
          )} */}
          {publishWithIFRDiscoveryDisplayCards.map(
            (publishWithCardDisplay, publishWithCardDisplayIndex) => (
              <Grid
                item
                xs={12}
                sm={publishWithCardDisplay?.imageTag === "article" ? 12 : 6}
                md={4}
                key={`publishWithDisplayCard_${publishWithCardDisplayIndex}`}
              >
                <PublishWithCardDisplay
                  title={publishWithCardDisplay?.title}
                  image={
                    publishWithCardDisplay?.imageTag === "membership" ? (
                      <TeamWork1 style={{ maxWidth: "350px" }} />
                    ) : publishWithCardDisplay?.imageTag === "conference" ? (
                      <TeamWork2 style={{ maxWidth: "350px" }} />
                    ) : (
                      <ArticleWriting style={{ maxWidth: "350px" }} />
                    )
                  }
                  description={publishWithCardDisplay?.description}
                />
              </Grid>
            )
          )}
        </Grid>
      </Grid>
      <Divider
        variant="middle"
        sx={{ borderColor: theme.palette.secondary.main }}
      />
      <Box
        sx={{
          marginTop: "20px",
          // background: theme.palette.primary.surface,
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
                textAlign: "center",
                // width: "100%",
                padding: "20px",

                marginBottom: "30px",
              }}
            >
              Publishing Support Hub
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            spacing={4}
            sx={{
              marginBottom: "50px",
            }}
          >
            {supportHubCards.map((supportHubCard, supportHubCardIndex) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={`supportHubCard_${supportHubCardIndex}`}
              >
                <SupportHubCard
                  title={supportHubCard?.title}
                  image={
                    supportHubCard?.imageTag === "creative" ? (
                      <CreativeTeam />
                    ) : supportHubCard?.imageTag === "assistance" ? (
                      <Assistance />
                    ) : supportHubCard?.imageTag === "review" ? (
                      <ArticleReview />
                    ) : (
                      <ArticleEditor />
                    )
                  }
                  description={supportHubCard?.description}
                  isButton={supportHubCard?.isButton}
                  buttonText={supportHubCard?.buttonText}
                  buttonActionType={supportHubCard?.buttonType}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Home;
