import React from "react";
import { useTheme } from "@emotion/react";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import { FaqPoints, TCPoints } from "../../../helpers/constant";
import * as DOMPurify from "dompurify";
import htmlParser from "html-react-parser";

const StyledBox = styled(Grid)(({ theme }) => ({
  width: "100%",
  height: "100%",
  // background: theme.palette.primary.main,
  borderRadius: theme.borderRadius[10],
  padding: "25px",
  boxShadow: theme.boxShadow.shadowThree,
  marginBottom: "10px",
  textAlign: "center",
}));

const Faq = () => {
  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        textAlign: "justify",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        FREQUENTLY ASKED QUESTIONS (FAQ)
      </Typography>

      {FaqPoints.map((faqPoint) => (
        <Box
          sx={{
            marginBottom: "30px",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            {faqPoint.question}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {htmlParser(DOMPurify.sanitize(faqPoint.ans))}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Faq;
