import { Box, Tab, Tabs, TabPanel, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddEditJournal from "./AddEditJournal";
import JournalArchive from "./JournalArchive";
import journalAdminServices from "../../../../services/journal/admin";
import { useParams, useSearchParams } from "react-router-dom";
import { Loader } from "../../../../components";
import subjectAdminServices from "../../../../services/subject/admin";

const Journal = () => {
  const { journalAddType } = useParams();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const [tabValue, setTabValue] = useState("addEdit");

  const handleTabChange = (e, tabNewValue) => {
    setTabValue(tabNewValue);
  };

  const [subjectOptions, setSubjectOptions] = useState([]);

  const [journalDetails, setJournalList] = useState({
    data: {
      name: "",
      abstractDescription: "",
      subject: [],
      coverImgUrl: "",
      abbrKeyTitle: "",
      openAccessYear: "",
      articleProcessCharge: "",
      issnOnline: "",
      issnOnlineLink: "",
      issnPrint: "",
      issnPrintLink: "",
      frequency: "",
      journalLanguage: "",
      origin: "",
      journalDescription: "",
      organizationDescription: "",
      aimsDescription: "",
    },
    loading: false,
    fetchFailed: false,
  });

  useEffect(() => {
    const fetchJournal = async () => {
      if (searchParams.get("journalId") && journalAddType === "edit") {
        setJournalList((prev) => ({
          ...prev,
          loading: true,
          fetchFailed: false,
        }));
        const resJournal = await journalAdminServices.getJournalById({
          journalId: searchParams.get("journalId"),
        });
        if (resJournal) {
          setJournalList((prev) => ({
            ...prev,
            data: resJournal.data,
            loading: false,
            fetchFailed: false,
          }));
        } else {
          setJournalList((prev) => ({
            ...prev,
            loading: false,
            fetchFailed: true,
          }));
        }
      }
    };
    fetchJournal();
  }, []);

  useEffect(() => {
    const fetchSubjects = async () => {
      const subjects = await subjectAdminServices.getSubjectList({
        sortBy: "",
        sortDirection: "",
        page: "",
        limit: "",
      });
      if (subjects?.data?.docs) {
        setSubjectOptions(subjects?.data?.docs);
      }
    };
    fetchSubjects();
  }, []);

  return journalDetails?.loading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Loader />
    </Box>
  ) : (
    <Box>
      <Tabs
        value={tabValue}
        onChange={(e, tabNewValue) => handleTabChange(e, tabNewValue)}
        variant="standard"
        sx={{ marginBottom: "30px" }}
      >
        <Tab value={"addEdit"} wrapped={false} label={"Add/Edit journal"} />
        <Tab value={"archive"} wrapped={false} label={"journal's archive"} />
      </Tabs>
      {tabValue === "addEdit" && (
        <AddEditJournal
          defaultFormValues={journalDetails.data}
          subjectOptions={subjectOptions}
        />
      )}
      {tabValue === "archive" && <JournalArchive />}
    </Box>
  );
};

export default Journal;
