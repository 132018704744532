import React, { useEffect } from "react";
import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import { ReactComponent as Guidelines } from "../../../assets/images/Guidelines.svg";
import {
  authGuidelinesArticlePoints,
  luminaryMembershipAdvantagePoints,
  luminaryMembershipEligibleCriteriaPoints,
} from "../../../helpers/constant";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchJournalById } from "../../../redux/slices/webJournal";
import * as DOMPurify from "dompurify";
import htmlParser from "html-react-parser";

const MainManuscriptOl = styled("ol")(({ theme }) => ({
  counterReset: "manuscript",

  "& > li": {
    counterIncrement: "manuscript",
  },
}));

const OrganizationOl = styled("ol")(({ theme }) => ({
  counterReset: "organization",
  listStyle: "none",
  margin: "0",
  paddingLeft: "23px",

  "& > li": {
    marginBottom: "20px",
    display: "table",
    counterIncrement: "organization",
    listStyleType: "none",

    "&:before": {
      display: "table-cell",
      content: 'counters(manuscript, ".") ". " counters(organization, ".")',
      paddingRight: "0.6em",
      width: "40px",
    },
  },
}));

const AuthorGuidelines = () => {
  const dispatch = useDispatch();
  const { journalId } = useParams();

  useEffect(() => {
    if (journalId) {
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  return (
    <Box
      sx={{
        padding: "20px 40px",
        height: "100%",
        wordBreak: "break-word",
        // background: theme.palette.primary.litSurface,
      }}
    >
      <Box sx={{ marginBottom: "30px" }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
            marginBottom: "20px",
          }}
        >
          AUTHOR GUIDELINES
        </Typography>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item sx={12} sm={12} md={7} lg={7}>
            <Typography
              //   variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              <strong>Article Types</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              Word limits or Page limit are provided for guidance only. The
              Editors will consider submissions that exceed the recommended
              limit, subject to feedback received during peer review.
            </Typography>
            <Typography
              //   variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              <strong>Article</strong>
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                marginBottom: "20px",
              }}
            >
              A manuscript reports the results of an original full-length study.
              An article has a comprehensive narrative that includes
              introduction, materials and methods, results, discussion, and
              references.
            </Typography>
            <ul>
              {authGuidelinesArticlePoints.article.map((point) => (
                <li>{point.text}</li>
              ))}
            </ul>
          </Grid>
          <Grid item sx={12} sm={12} md={5} lg={5}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Guidelines style={{ maxWidth: "400px" }} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
        <Typography
          //   variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>Review</strong>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          A review should comprehensively and systematically analyze and discuss
          the existing literature related to a specific topic. Expert synthesis,
          assessment, future direction, and challenges are expected. Generally,
          a review article should have an introduction to provide the rationale
          for the review, followed by sections with appropriate subheadings, and
          a perspective section at the end. The standard headings
          (acknowledgements, contributions, competing interests, funding) are
          also required. All invited reviews will undergo peer review prior to
          acceptance. Before conceiving and writing a review, it is encouraged
          to consult the Editor-in-Chief first.
        </Typography>
        <ul>
          {authGuidelinesArticlePoints.review.map((point) => (
            <li>{point.text}</li>
          ))}
        </ul>
      </Box>
      <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
        <Typography
          //   variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>Method</strong>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          A report of a study that focuses on a new method, technique, or
          technology that is widely useful and likely to impact the way we carry
          out research. Some technical details and recommendations for applying
          the method are recommended.
        </Typography>
        <ul>
          {authGuidelinesArticlePoints.method.map((point) => (
            <li>{point.text}</li>
          ))}
        </ul>
      </Box>
      <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
        <Typography
          //   variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>Editorial</strong>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          An authoritative, timely opinion on an issue or a paper; it is usually
          written or invited by the Editor-in-Chief.
        </Typography>
        <ul>
          {authGuidelinesArticlePoints.editorial.map((point) => (
            <li>{point.text}</li>
          ))}
        </ul>
      </Box>
      <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
        <Typography
          //   variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>Perspective</strong>
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            marginBottom: "20px",
          }}
        >
          A perspective article expresses an opinion or a new perspective about
          existing research on a particular topic. Perspectives propose and
          support a new hypothesis, discuss the implications of a newly
          implemented innovation, or present unique viewpoints on existing
          problems, fundamental concepts, or prevalent notions on a specific
          topic. A perspective article may focus on current advances and future
          directions on a topic and may include original data.
        </Typography>
        <ul>
          {authGuidelinesArticlePoints.perspective.map((point) => (
            <li>{point.text}</li>
          ))}
        </ul>
      </Box>
      <Box sx={{ marginBottom: "30px", textAlign: "justify" }}>
        <Typography
          //   variant="h5"
          gutterBottom
          sx={{
            fontWeight: 600,
          }}
        >
          <strong>Manuscript Preparation: Format, structure, and style</strong>
        </Typography>
        <MainManuscriptOl>
          <li
            style={{
              marginBottom: "20px",
            }}
          >
            <Typography
              //   variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              <strong>Content</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
              The articles that are published should have the following
              components in the order stated.
            </Typography>
            <ul>
              {authGuidelinesArticlePoints.manuscriptContent.map((point) => (
                <li>{point.text}</li>
              ))}
            </ul>
          </li>
          <li
            style={{
              marginBottom: "20px",
            }}
          >
            <Typography
              //   variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              <strong>Cover letter</strong>
            </Typography>

            <Typography variant="body1" gutterBottom>
              Should include the following:
            </Typography>
            <ul>
              {authGuidelinesArticlePoints.manuscriptCoverLetter.map(
                (point) => (
                  <li>{point.text}</li>
                )
              )}
            </ul>
          </li>

          <li
            style={{
              marginBottom: "20px",
            }}
          >
            <Typography
              //   variant="h5"
              gutterBottom
              sx={{
                fontWeight: 600,
              }}
            >
              <strong>Organization of Manuscripts</strong>
            </Typography>
            <OrganizationOl>
              <li>
                <Typography gutterBottom>Initial Submission</Typography>

                <Typography gutterBottom>
                  The submission should contain the following:
                </Typography>

                <ol>
                  {authGuidelinesArticlePoints.manuscriptOrganizationSubmission.map(
                    (point) => (
                      <li>{point.text}</li>
                    )
                  )}
                </ol>
                <Typography
                  gutterBottom
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  For initial submissions (i.e. NOT revised manuscripts), we
                  strongly suggest you insert all figures and tables into an
                  appropriate position (the same or next page of the first
                  citation) in the main text (Microsoft Word) to facilitate the
                  review process. The figure legends, and table captions should
                  be inserted immediately above or below the figures and tables,
                  respectively. Supplementary materials should be appended to
                  the end of the References, with the supplemental tables and
                  figures being embedded or stand alone. Finally, the manuscript
                  should include continuous line numbers and page numbers to
                  facilitate commenting. Manuscripts following the outlined
                  format will expedite the review process.
                </Typography>
              </li>
              <li>
                <Typography gutterBottom>Submission of Revisions</Typography>

                <Typography gutterBottom>
                  For revised manuscripts, we suggest you submit the manuscript
                  text, tables and figures, and supplementary files separately.
                  Your submission should include:
                </Typography>

                <ul>
                  {authGuidelinesArticlePoints.manuscriptOrganizationSubRevisions.map(
                    (point) => (
                      <li>{point.text}</li>
                    )
                  )}
                </ul>
                <Typography
                  gutterBottom
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  Regardless of whether this is an initial submission or
                  resubmission, more detailed and general information for
                  preparing a manuscript is given in the next section titled
                  "Detailed Requirements".
                </Typography>
              </li>
              <li>
                <Typography gutterBottom>Detailed Requirements</Typography>

                <Typography gutterBottom>
                  Before submitting your manuscript, please check your
                  submission for the following:
                </Typography>

                <ol>
                  {authGuidelinesArticlePoints.manuscriptOrganizationRequirements.map(
                    (point) => (
                      <li
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <Typography gutterBottom>{point?.text}</Typography>
                        {point?.description &&
                          point.description
                            .split("***")
                            .map((pointText) => (
                              <Typography gutterBottom>{pointText}</Typography>
                            ))}
                        {point?.points?.length > 0 ? (
                          point?.listType === "ol" ? (
                            <ol
                              type={point.olType}
                              style={{
                                marginBottom: "20px",
                              }}
                            >
                              {point.points.map((nestedPoint) => (
                                <li>{nestedPoint.text}</li>
                              ))}
                            </ol>
                          ) : (
                            <ul
                              style={{
                                marginBottom: "20px",
                              }}
                            >
                              {point.points.map((nestedPoint) => (
                                <li>{nestedPoint.text}</li>
                              ))}
                            </ul>
                          )
                        ) : null}
                        {point.egText && (
                          <Typography
                            gutterBottom
                            sx={{
                              marginBottom: "20px",
                            }}
                          >
                            <strong>{point?.egText}</strong>
                          </Typography>
                        )}
                        {point?.examples?.length > 0 &&
                          point?.examples?.map((egPoint) => (
                            <>
                              <Typography gutterBottom>
                                <strong>{egPoint.text}</strong>
                              </Typography>
                              <Typography gutterBottom>
                                {egPoint.description}
                              </Typography>
                              {egPoint?.points?.length > 0 && (
                                <ol
                                  type={point.olType}
                                  style={{
                                    marginBottom: "20px",
                                  }}
                                >
                                  {egPoint.points.map((egNestedPoint) => (
                                    <li>
                                      {egNestedPoint.text && (
                                        <Typography gutterBottom>
                                          {egNestedPoint.text}
                                        </Typography>
                                      )}
                                      {egNestedPoint.description && (
                                        <Typography gutterBottom>
                                          {htmlParser(
                                            DOMPurify.sanitize(
                                              egNestedPoint.description
                                            )
                                          )}
                                        </Typography>
                                      )}
                                    </li>
                                  ))}
                                </ol>
                              )}
                            </>
                          ))}
                      </li>
                    )
                  )}
                </ol>
              </li>
            </OrganizationOl>
          </li>
        </MainManuscriptOl>
      </Box>
    </Box>
  );
};

export default AuthorGuidelines;
